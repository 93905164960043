/**
 * Each stage refers to a .env.<stage> and defines differences between qa, uat and prod builds.
 */
export const STAGES = ['qa', 'uat', 'uatr', 'regression', 'prod']
const STAGES_HPRODUCE = ['qa', 'uat', 'regression', 'prod']

/**
 * Which products are included in Monorepo?
 * The product names are the same as the filenames in ./packages
 */
export const PRODUCTS = [
  'adminconsole',
  'authenticator',
  'hcem',
  'hproduce',
  'hub',
  'cockpit',
  'onsite',
  'truckmonitor',
  'maintain',
  'metricsui',
  'pom',
  'legal',
  'datalink',
  'slot',
  'invitation',
  'settings'
]

/**
 * Which packages should be covered by the coverage report
 * Must be a folder in ./packages
 */
export const COVERAGE_PACKAGES = [...PRODUCTS, 'uikit', 'apiclient', 'automate']

/**
 * Which packages should be covered by the eslint report
 * Must be a folder in ./packages
 */
export const ESLINT_PACKAGES = [...PRODUCTS, 'uikit', 'apiclient', 'automate', 'metricsapi']

/**
 * Shorter name for the product.
 */
export const PRODUCT_TO_SHORT_NAME = {
  // use by default the full name
  ...PRODUCTS.reduce((acc, curr) => {
    acc[curr] = curr
    return acc
  }, {}),
  // override with shorter version if necessary
  adminconsole: 'admin',
  authenticator: 'auth',
  hcem: 'hcem',
  hproduce: 'hproduce',
  maintain: 'maintain',
  hub: 'hub',
  onsite: 'onsite_preview', // FIXME: reduce length, need to rename env vars on Gitlab
  cockpit: 'cockpit',
  truckmonitor: 'tmon',
  slot: 'slot',
  invitation: 'invitation',
  settings: 'settings'
}

/**
 * bucket name on Azure storage account
 */
export const PRODUCT_TO_AZURE_STORAGE_ACCOUNT_BUCKET_NAME = {
  adminconsole: 'admin',
  authenticator: 'auth',
  hcem: 'hcem',
  hproduce: 'hproduce',
  hub: 'hub',
  onsite: 'onsite',
  maintain: 'maintain',
  cockpit: 'cockpit',
  truckmonitor: 'tmon',
  metricsui: 'metricsui',
  legal: 'legal',
  datalink: 'dlportal',
  pom: 'pom',
  slot: 'slot',
  invitation: 'invitation',
  settings: 'settings'
}

/**
 * bucket name on CDN
 */
export const PRODUCT_TO_CDN_NAME = {
  adminconsole: 'manage',
  authenticator: 'auth',
  hcem: 'hcem',
  hproduce: 'hproduce',
  maintain: 'maintain',
  hub: 'hub',
  onsite: 'onsite',
  cockpit: 'cockpit',
  truckmonitor: 'tmon',
  metricsui: 'metricsui',
  legal: 'legaldocs',
  datalink: 'dlportal',
  pom: 'pom',
  slot: 'slot',
  invitation: 'invitation',
  settings: 'settings'
}

/**
 * Which products have which stages?
 */
export const PRODUCT_TO_STAGES = {
  adminconsole: [...STAGES],
  authenticator: [...STAGES],
  maintain: [...STAGES_HPRODUCE],
  hcem: [...STAGES_HPRODUCE],
  hproduce: [...STAGES_HPRODUCE],
  hub: [...STAGES],
  cockpit: [...STAGES_HPRODUCE],
  onsite: [...STAGES],
  truckmonitor: [...STAGES],
  legal: ['qa', 'uat', 'prod'],
  datalink: [...STAGES],
  metricsui: ['qa'],
  pom: [...STAGES_HPRODUCE],
  slot: [...STAGES],
  invitation: [...STAGES],
  settings: ['qa', 'uat', 'prod']
}
